export default {
  data() {
    return {
      query: {},
      // 表格分页的相关配置
      pagination: {
        layout: "prev, pager, next",
        pageSizes: ["10", "20", "50", "100"],
        pageSize: 32, // 对应defaultPageSize
        defaultPageSize: 32, // 不写默认10，为了测试少数据分页可修改为1
        total: 0,
        currentPage: 1
      }
    };
  },
  methods: {
    // 分页、筛选变化时触发
    handleCurrentChange(currentPage) {
      console.log(9999999, currentPage);
      // 判断切换当前页面数值 需要重置到第一页
      const pagination = { currentPage: 1 };
      pagination.currentPage = currentPage;
      Object.assign(this.pagination, pagination);
      this.getPage();
    },
    // 处理要请求数据
    getPage() {
      console.log("getPagegetPagegetPage", this.pagination);
      const { pageSize, currentPage } = this.pagination;
      const data = {
        ...this.query,
        limit: pageSize,
        page: currentPage
      };
      this.getList(data);
    }
  }
};
