<template>
  <div class="search-page bg-dark">
    <header>
      <div class="header-mask"></div>
      <div class="header-icon">
        <img src="@/assets/images/person.png" />
      </div>
      <div class="search-box1">
        <div class="search-box2">
          <div class="search-item">
            <div class="search-con" :class="{ isHide: !query.keywords }">
              <span v-if="query.keywords">{{ query.keywords }}</span>
            </div>
            <div class="category">
              <span
                v-for="item in category"
                :key="item.value"
                :class="{ active: item.label === query.category_name }"
                @click="filterTagItem(item.label)"
                >{{ item.label }}</span
              >
            </div>
            <div class="filter">
              <span
                v-for="item in filters"
                :key="item.value"
                :class="{ active: item.value === query.search_type }"
                @click="filterItem(item.value)"
                >{{ item.label }}</span
              >
            </div>
          </div>
          <search
            @change="searchVal"
            :type="'video'"
            :text="query.keywords"
          ></search>
        </div>
      </div>
    </header>
    <main>
      <div class="result-item" v-if="videoList.length > 0">
        <div class="grid-col" v-for="item in videoList" :key="item.id">
          <div
            class="grid-content"
            @mouseenter="mouseenter(item.id)"
            @mouseleave="mouseleave(item.id)"
          >
            <el-image
              style="height: 200px;min-width: 100%"
              :src="item.resource_fimage"
              @contextmenu="handleMouse"
              :fit="'cover'"
              lazy
              :class="{ 'current-image': item.id === current }"
            ></el-image>
            <div class="sample-player-box" @click="handleDetail(item.id)">
              <div class="info-block right-top">{{ item.quality }}</div>
              <div class="info-block left-top">{{ item.duration }}</div>
              <div class="video-player">
                <video
                  @contextmenu="handleMouse"
                  :src="item.resource_path"
                  :autoplay="true"
                  loop="loop"
                  class="video"
                  v-if="item.id === current"
                >
                  您的浏览器不支持 video 标签。
                </video>
              </div>
            </div>
            <div class="grid-text">
              <div class="grid-oper">
                <p class="name">{{ item.title }}</p>
                <div>
                  <img
                    src="@/assets/images/collection-1.png"
                    @click="handleCollection(item.id)"
                  />
                  <img
                    src="@/assets/images/forward-1.png"
                    @click="handleShare(item)"
                  />
                  <img
                    v-if="item.is_zan"
                    src="@/assets/images/like-fill.png"
                    @click="handleLike(item.id)"
                  />
                  <img
                    v-else
                    src="@/assets/images/like-1.png"
                    @click="handleLike(item.id)"
                  />
                  <span>{{ item.fabulous }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="empty-text">
        抱歉，暂无查询到相关结果，期待您的作品加入
      </div>
      <el-pagination
        @current-change="handleCurrentChange"
        :page-size="32"
        :current-page="pagination.currentPage"
        layout="prev, pager, next"
        :total="total"
        v-if="total > 0"
      >
      </el-pagination>
    </main>
    <!--分享组件-->
    <poster ref="Poster"></poster>
  </div>
</template>

<script>
import search from "@/components/search/index.vue";
import pageMixin from "@/mixins/searchTbVideoPage";
import poster from "@/components/sharePoster/index.vue";
import toLogin from "@/mixins/toLogin";
import merge from "webpack-merge";
export default {
  name: "index",
  mixins: [pageMixin, toLogin],
  components: { search, poster },
  data() {
    return {
      query: {
        keywords: "",
        search_type: undefined,
        resource_type: 2, //1图片2视频
        category_name: undefined
      }, //默认参数
      videoList: [],
      category: [
        {
          label: "动物",
          value: "1"
        },
        {
          label: "植物",
          value: "2"
        },
        {
          label: "自然",
          value: "3"
        },
        {
          label: "人文",
          value: "4"
        },
        {
          label: "天文",
          value: "5"
        },
        {
          label: "水下",
          value: "6"
        },
        {
          label: "运动",
          value: "7"
        },
        {
          label: "纪录片",
          value: "8"
        },
        {
          label: "Vlog",
          value: "9"
        },
        {
          label: "科技",
          value: "10"
        }
      ],
      filters: [
        {
          label: "推荐",
          value: 1
        },
        {
          label: "最新",
          value: 2
        },
        {
          label: "点赞最多",
          value: 3
        },
        {
          label: "收藏最多",
          value: 4
        }
      ],
      current: "",
      currentPage: 1,
      total: 0
    };
  },
  created() {
    this.query.keywords = this.$route.query.text;
    const user_id = localStorage.getItem("user_id");
    this.user_id = user_id;
    // 调取搜索列表方法
    this.getPage();
  },
  watch: {
    $route() {
      if (this.$route.query.text) {
        this.query.keywords = this.$route.query.text;
        const user_id = localStorage.getItem("user_id");
        this.user_id = user_id;
        // 调取搜索列表方法
        this.getPage();
      }
    }
  },
  methods: {
    handleMouse(e) {
      e.preventDefault();
    },
    //搜素tag分类过滤
    filterTagItem(val) {
      if (this.query.category_name == val) {
        this.query.category_name = undefined;
      } else {
        this.query.category_name = val;
      }
      this.pagination.currentPage = 1;
      //调取搜索列表方法
      this.getPage();
    },
    //搜索条件过滤
    filterItem(val) {
      if (this.query.search_type == val) {
        this.query.search_type = undefined;
      } else {
        this.query.search_type = val;
      }
      this.pagination.currentPage = 1;
      //调取搜索列表方法
      this.getPage();
    },
    //搜索内容
    searchVal(value) {
      const { text, type } = value;

      if (type == "picture") {
        this.$router.push({
          name: "pictureSearch",
          query: { type, text }
        });
      }
      if (type == "video") {
        this.$router.push({
          query: merge(this.$route.query, { text: value.text })
        });
        this.query.keywords = value.text;
        this.pagination.currentPage = 1;
        //调取搜索列表方法
        this.getPage();
      }
    },
    //搜索接口列表
    async getList(params) {
      // console.log("getListgetList", params);
      const { data } = await this.$api.getSearch(params);
      if (data.code === 1) {
        // console.log("获取列表", data);
        this.videoList = data.data;
        // console.log(data.total);
        this.total = data.total;
      }
    },
    handleDetail(id) {
      this.$router.push({
        name: "videoPreview",
        query: { id }
      });
    },
    mouseenter(id) {
      this.current = id;
    },
    mouseleave() {
      this.current = "";
    },
    async handleCollection(id) {
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      const { data } = await this.$api.postShouCang({
        resource_id: id,
        user_id: this.user_id
      });
      if (data.code === 1) {
        this.$message({
          message: data.msg,
          type: "success"
        });
      } else {
        this.$message({
          message: data.msg,
          type: "warning"
        });
      }
    },
    async handleShare(info) {
      console.log(99999999, info);
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      const qrContent =
        window.location.origin + "#/video/preview?id=" + info.id + "";
      const posterInfo = {
        nickname: info.nickname,
        headimage: info.headimage,
        user_description: info.user_description,
        url: info.resource_fimage || info.resource_path,
        title: info.title,
        description: info.description,
        resource_id: info.id,
        content: qrContent
      };
      this.$refs["Poster"].show(posterInfo);
      const { data } = await this.$api.postZhuanFa({
        resource_id: info.id,
        user_id: this.user_id
      });
      if (data.code === 1) {
        console.log(data.msg);
      }
    },
    async handleLike(id) {
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      console.log(789, id);
      const { data } = await this.$api.postClickZan({
        resource_id: id,
        user_id: this.user_id
      });
      const resource = this.videoList.filter(i => i.id === id)[0];
      if (data.code === 1) {
        this.$message({
          message: data.msg,
          type: "success"
        });
        resource.fabulous = resource.fabulous + 1;
      } else {
        this.$message({
          message: data.msg,
          type: "warning"
        });
        resource.fabulous = resource.fabulous - 1;
      }
      resource.is_zan = !resource.is_zan;
    }
  }
};
</script>

<style lang="scss">
.search-page {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  header {
    display: flex;
    justify-content: space-between;
    background: url("../../../assets/images/video-bg.png");
    background-size: 100% 100%;
    position: relative;
    height: 339px;
    .header-mask {
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.4) 100%);
      position: absolute;
      top: 0;
      left: 0;
    }
    .header-icon {
      position: absolute;
      right: 99px;
      top: 191px;
      display: none;
    }
    .search-box1 {
      width: 100%;
      padding: 88px 95px 119px 99px;
      position: absolute;
    }
    .search-box2 {
      display: flex;
      justify-content: space-between;
    }
    .search-item {
      .search-con {
        font-size: 47px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        color: #ffffff;
        padding-left: 10px;
        position: relative;
        margin-bottom: 28px;
        height: 47px;
        &:after {
          content: " ";
          position: absolute;
          left: -2px;
          top: 0;
          width: 6px;
          height: 100%;
          background: #e60021;
          border-radius: 3px;
        }
      }
      .isHide {
        &:after {
          display: none;
        }
      }
      .category {
        margin-bottom: 20px;
        span {
          margin-right: 26px;
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ffffff;
          cursor: pointer;
        }
        .active {
          color: #e60021;
        }
      }
      .filter {
        span {
          padding: 0 10px;
          height: 20px;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          border-radius: 5px;
          background: #7d7d7d;
          margin-right: 23px;
          cursor: pointer;
        }
        .active {
          background: #e60021;
        }
      }
    }
  }
  main {
    margin-top: -47px;
    padding-bottom: 120px;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    .empty-text {
      text-align: center;
      margin-top: 150px;
    }
    .result-item {
      width: 100%;
      padding: 0 95px 100px 99px;
      .grid-col {
        padding: 0 7px;
        width: 25%;
        display: inline-block;
        .grid-content {
          position: relative;
          margin-bottom: 7px;

          cursor: pointer;
          .current-image {
            opacity: 0;
          }
          .sample-player-box {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 154px;
            overflow: hidden;
            z-index: 0;
            cursor: pointer;
            .video-player {
              .video {
                width: 100%;
              }
            }
            .info-block {
              z-index: 10;
              font-size: 14px;
              position: absolute;
              color: #999;
              background-color: #080808;
              padding: 2px 6px;
              border-radius: 3px;
              opacity: 0.8;
            }
            .right-top {
              right: 10px;
              top: 8px;
            }
            .left-top {
              left: 10px;
              top: 8px;
            }
          }
          .grid-text {
            display: none;
          }
          .grid-oper {
            position: absolute;
            bottom: 0;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.65) 0%,
              rgba(0, 0, 0, 0) 100%
            );
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: 28px;
            align-items: center;
            padding: 0 12px;
            font-size: 14px;
            .name {
              width: 50%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: 17px;
              font-family: PingFang SC;
              font-weight: 300;
              color: #ffffff;
            }
            img {
              height: 17px;
              cursor: pointer;
              margin-left: 8px;
            }
            span {
              margin-left: 5px;
              font-size: 17px;
              font-family: PingFang SC;
              font-weight: 300;
              color: #ffffff;
              position: relative;
              top: -3px;
            }
          }
          &:hover {
            .grid-text {
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
